import { useCurrentTenant, useIsMobile } from "app/jm";
import {
  OAuthProvider,
  signInWithEmailAndPassword,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import { app } from "app/Firebase";
import type { FormEvent } from "react";
import { useCallback, useState } from "react";
import { FirebaseError } from "firebase/app";
import { Button, FormGroup, InputGroup } from "@blueprintjs/core";
import Toaster from "src/app/Toaster";

const auth = getAuth(app);

const microsoftProvider = new OAuthProvider("microsoft.com");
microsoftProvider.setCustomParameters({
  tenant: "1aa2a47f-4fd3-462d-b36d-320b5d3dfda1",
  prompt: "select_account",
});

export const Login = () => {
  const { tenantId, publicTenantAssetsUrl, themeColour } = useCurrentTenant();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const isMobile = useIsMobile();
  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      setIsSubmitting(true);
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const object = Object.fromEntries(formData.entries());
      if (tenantId !== "jmadmin" && !auth.tenantId) {
        throw new Error("No tenantId set");
      }
      try {
        const signInResult = await signInWithEmailAndPassword(
          auth,
          String(object.email),
          String(object.password)
        );
        console.log({ signInResult });
      } catch (error) {
        console.log({ error });
        setIsSubmitting(false);
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case "auth/invalid-email":
              Toaster.error("Invalid email address");
              break;
            case "auth/missing-password":
              Toaster.error("Missing password");
              break;
            case "auth/user-not-found":
              Toaster.error("User not found");
              break;
            case "auth/wrong-password":
              Toaster.error("Incorrect password");
              break;
            default:
              Toaster.error("An unknown error occurred");
              break;
          }
          return;
        }
        Toaster.error("An unknown error occurred");
      }
    },
    []
  );
  return (
    <div
      style={{
        width: "100%",
        backgroundSize: "cover",
        height: "100%",
        padding: 0,
        overflow: "hidden",
        display: isMobile ? undefined : "grid",
        placeItems: "center",
        gridTemplateRows: "4fr 2fr 4fr 4fr",
        background: "rgba(0,0,0,0.5)",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "grid",
          placeItems: "center",
          gridRow: 2,
        }}
      >
        <div
          //className="jm-login-form"
          className={`bg-white/85 ${isMobile ? "min-w-[100%] h-[100%] p-3" : "min-w-[400px] rounded-3xl p-4 "} max-w-[400px]`}
        >
          <div
            className="jm-login-logo-header"
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              paddingBottom: "2em",
            }}
          >
            {tenantId === "jmadmin" ? (
              <span className="text-lg">Job Manager Admin</span>
            ) : (
              <img
                className="jm-login-tenant-logo"
                src={`${publicTenantAssetsUrl}/logo.png`}
                style={{ width: 90 }}
              />
            )}
            <img
              className="jm-login-logo"
              src="assets/img/jm_logo_small.png"
              style={{ width: 160 }}
            />
          </div>
          <form onSubmit={isSubmitting ? () => null : handleSubmit}>
            <FormGroup label="Username">
              <InputGroup type="email" name="email" />
            </FormGroup>

            <FormGroup label="Password">
              <InputGroup type="password" name="password" />
            </FormGroup>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button intent="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
              {tenantId !== "jmadmin" && (
                <a
                  id="forgot"
                  href="#"
                  onClick={() => {
                    console.log("To be implemented...");
                  }}
                >
                  Forgot password
                </a>
              )}
            </div>
          </form>
          <hr className="mt-2" />
          {tenantId !== "jmadmin" && (
            <div className="mt-2">
              <Button
                onClick={() => {
                  if (tenantId !== "jmadmin" && !auth.tenantId) {
                    throw new Error("No tenantId set");
                  }
                  signInWithPopup(auth, microsoftProvider).catch((error) => {
                    console.log(error);
                  });
                }}
                // Need to use inline styles because Tailwind doesn't support
                // dynamic arbitrary styles
                // https://v2.tailwindcss.com/docs/just-in-time-mode#known-limitations
                style={{
                  background: themeColour,
                }}
                className="text-white w-[100%]"
                disabled={isSubmitting}
              >
                Staff & Contractor Login
              </Button>
            </div>
          )}
          <p
            style={{
              color: "#666",
              paddingTop: 10,
              textAlign: "center",
              fontSize: 10,
              marginBottom: 0,
            }}
          >
            Version: {window.JM_VERSION}
          </p>
        </div>
      </div>
    </div>
  );
};
