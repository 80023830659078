import { initializeApp, type FirebaseApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { Buffer } from "buffer";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

let app: FirebaseApp;

if (
  window.JM_ENVIRONMENT === "DEVELOPMENT" ||
  window.JM_ENVIRONMENT === "TESTING"
) {
  const firebaseEmulatorHost =
    import.meta.env.VITE_FIREBASE_EMULATOR_HOST || "localhost";

  app = initializeApp({
    projectId: "demo-job-manager",
    apiKey: "dummy",
    authDomain: `${firebaseEmulatorHost}:19099`,
    storageBucket: "demo-job-manager.appspot.com",
  });

  const auth = getAuth(app);

  connectAuthEmulator(auth, `https://${firebaseEmulatorHost}:19099`, {
    disableWarnings: true,
  });

  const storage = getStorage(app);
  connectStorageEmulator(storage, firebaseEmulatorHost, 19199);

  /** @ts-expect-error We are hacking ssl support */
  storage._protocol = "https";

  const db = getFirestore();
  connectFirestoreEmulator(db, firebaseEmulatorHost, 19299);

  /** @ts-expect-error We are hacking ssl support */
  db["_settings"]["ssl"] = true;
} else {
  if (window.JM_FIREBASE_CONFIG_BASE64 === undefined) {
    throw new Error("window.JM_FIREBASE_CONFIG_BASE64 is undefined");
  }
  // It is safe to have these ids committed to version control
  // See https://firebase.google.com/docs/projects/api-keys
  const firebaseConfig = JSON.parse(
    Buffer.from(window.JM_FIREBASE_CONFIG_BASE64, "base64").toString()
  );
  app = initializeApp(firebaseConfig);
}

// Note we no longer export firestore, auth etc
// As this was affecting bundle size.
export { app };
