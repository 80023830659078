import { useEffect, lazy, Suspense, type ReactNode, useState } from "react";
import { app } from "app/Firebase";
import "app/common.css";
import type { ResultOf } from "src/jmApiClient";
import { useFetchJmApiQuery } from "src/jmApiClient";
import { ClientError } from "graphql-request";
import Toaster from "src/app/Toaster";
import "src/app/app.css"; // This is currently imported both here and in App.tsx... not needed in App?
import { useCurrentTenant } from "app/jm";
import { mobileAndTabletCheck } from "app/jm";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { Login } from "src/app/Login";
import { assign, createMachine, fromPromise } from "xstate";
import { useMachine } from "@xstate/react";
import axios from "axios";
import { GetCurrentUserQueryDocument } from "./jm";
import type { CardProps } from "@blueprintjs/core";
import { Alert, Card, Spinner } from "@blueprintjs/core";
import { assert } from "lib/util/assert";
import { getAuth, type User } from "firebase/auth";
import { ReactQueryDevtoolsPanel } from "@tanstack/react-query-devtools";
import devToolsConfigAtom from "./devTools/devToolsConfigAtom";
import { useAtom } from "jotai";
import * as Sentry from "@sentry/react";
import NetworkStatus from "./NetworkStatus";

const auth = getAuth(app);

const isMobileOrTablet = mobileAndTabletCheck();

// Check if we're on mobile/tablet and load the appropriate css
async function loadMobileCSS() {
  if (isMobileOrTablet) {
    await import("src/app/mobile.css");
  }
}
void loadMobileCSS();

const DesktopApp = lazy(() => import("src/app/DesktopApp"));
const AdminApp = lazy(() => import("src/app/AdminApp"));
const MobileApp = lazy(() => import("src/app/MobileApp"));
const CustomerApp = lazy(() => import("src/app/CustomerApp"));

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (window.JM_ENVIRONMENT === "DEVELOPMENT") {
        if (error instanceof ClientError) {
          throw JSON.stringify(error.response);
          //Toaster.error(<pre>{JSON.stringify(error.response, null, 2)}</pre>);
        }
        return;
      }
      Toaster.warning(
        "It looks like there is a connection problem. Please try again in a few minutes."
      );
      console.error(error);
      Sentry.captureException(error);
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: window.JM_ENVIRONMENT === "DEVELOPMENT" ? false : 3,
    },
  },
});

// Read url query param
const urlParams = new URLSearchParams(window.location.search);
const isCustomer = urlParams.get("customer");

function Status({ text, cardProps }: { text?: string; cardProps?: CardProps }) {
  return (
    <Card
      elevation={2}
      style={{
        maxWidth: 180,
        textAlign: "center",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 15,
        background: "white",
        borderRadius: 12,
      }}
      {...cardProps}
    >
      <Spinner size={16} />
      {text && (
        <span style={{ fontSize: 15, paddingLeft: 12, paddingTop: 0 }}>
          {text}
        </span>
      )}
    </Card>
  );
}

const TenantBackground = ({ children }: { children?: ReactNode }) => {
  const { tenantId, publicTenantAssetsUrl } = useCurrentTenant();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        backgroundImage: tenantId
          ? `url(${publicTenantAssetsUrl}/wallpaper.jpg)`
          : "none",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      {children}
    </div>
  );
};

const authMachine = createMachine({
  id: "authMachine",
  types: {} as {
    context: {
      // This causes OOM errors when using hot module reloading
      // https://github.com/statelyai/xstate/issues/4645
      // The issue is any object that contains circular references
      // causes xstate to recurse infinitely when persisting context
      // between reloads. :/
      // The answer is not to store the firebase user in context, but
      // just the details we need
      //firebaseUser: User | null;
      firebaseUserDetails: {
        uid: string;
        tenantId: string | null;
      } | null;
      jmUser: ResultOf<typeof GetCurrentUserQueryDocument> | null;
    };
    events:
      | {
          type: "FIREBASE_SIGNED_IN";
          user: User;
        }
      | { type: "FIREBASE_SIGNED_OUT" };
    actions:
      | {
          type: "assignFirebaseUser";
          params: { user: User };
        }
      | {
          type: "setSentryUser";
          params: { user: User };
        }
      | { type: "unassignFirebaseUser" }
      | { type: "setFirebaseTenantId"; params: { tenantId: string } }
      | { type: "reloadPage" }
      | { type: "signOutFirebase" }
      | { type: "showUserAuthenticityError" }
      | { type: "notifyTenantMismatch" };
  },
  initial: "loading",
  context: {
    //firebaseUser: null,
    firebaseUserDetails: null,
    jmUser: null,
  },
  states: {
    loading: {
      type: "parallel",
      states: {
        loadingTenant: {
          initial: "loading",
          states: {
            loading: {
              invoke: {
                id: "loadTenant",
                src: "loadTenant",
                onDone: {
                  target: "done",
                  actions: [
                    {
                      type: "setFirebaseTenantId",
                      params: ({ event }) => ({ tenantId: event.output }),
                    },
                  ],
                },
              },
            },
            done: {
              type: "final",
            },
          },
        },
        loadingFirebase: {
          initial: "loading",
          states: {
            loading: {
              on: {
                FIREBASE_SIGNED_IN: {
                  target: "done",
                  actions: [
                    {
                      type: "assignFirebaseUser",
                      params: ({ event }) => ({ user: event.user }),
                    },
                    {
                      type: "setSentryUser",
                      params: ({ event }) => ({ user: event.user }),
                    },
                  ],
                },
                FIREBASE_SIGNED_OUT: {
                  target: "done",
                  actions: [{ type: "unassignFirebaseUser" }],
                },
              },
            },
            done: {
              type: "final",
            },
          },
        },
      },
      onDone: [
        {
          target: "loggingIn",
          guard: "hasFirebaseUser",
        },
        {
          target: "loggedOut",
        },
      ],
    },
    loggedOut: {
      on: {
        FIREBASE_SIGNED_IN: {
          target: "loggingIn",
          actions: [
            {
              type: "assignFirebaseUser",
              params: ({ event }) => ({ user: event.user }),
            },
            {
              type: "setSentryUser",
              params: ({ event }) => ({ user: event.user }),
            },
          ],
        },
      },
    },
    loggingIn: {
      initial: "checkingTenantMatches",
      states: {
        checkingTenantMatches: {
          always: [
            {
              target: "checkingUserAuthenticity",
              guard: "checkTenantMatches",
            },
            {
              target: "#authMachine.loggedOut",
              actions: ["signOutFirebase", "notifyTenantMismatch"],
            },
          ],
        },
        checkingUserAuthenticity: {
          invoke: {
            id: "checkUserAuthenticity",
            src: "checkUserAuthenticity",
            onDone: {
              target: "done",
            },
            onError: {
              actions: ["signOutFirebase", "showUserAuthenticityError"],
            },
          },
        },
        done: {
          type: "final",
        },
      },
      onDone: {
        target: "loggedIn",
      },
    },
    loggedIn: {
      on: {
        FIREBASE_SIGNED_OUT: {
          target: "loggingOut",
        },
      },
    },
    // The firebase user is already logged out at this point
    // but we use this state to show a separate "logging out"
    // screen as otherwise open components will render oddly
    // due to the sudden lack of a logged in user.
    loggingOut: {
      // Reload the page to clear all global state
      entry: [{ type: "reloadPage" }],
    },
  },
});

function AuthContext() {
  const { tenantId } = useCurrentTenant();
  const fetchJmApiQuery = useFetchJmApiQuery();

  const [state, send] = useMachine(
    authMachine.provide({
      actors: {
        loadTenant: fromPromise(async () => {
          //console.log("SERVICE loadTenant");
          if (tenantId === "jmadmin") {
            return "jmadmin";
          }
          const response = await axios.get(`api/tenant/${tenantId}`);
          return response.data.firebaseTenantId;
        }),
        checkUserAuthenticity: fromPromise(async () => {
          //console.log("SERVICE checkUserExistsInJm");
          // This both checks the user exists, and also serves to preload
          // portal item data so we don't have to wait for menu to load.
          if (tenantId === "jmadmin") {
            return;
          }
          const response = await fetchJmApiQuery(
            GetCurrentUserQueryDocument,
            {}
          );
          const user = response.currentUser;
          console.log({ response });
          if (!user) {
            throw new Error("User does not exist in Job Manager");
          }
          if (!user.isActive) {
            throw new Error("User is disabled in Job Manager");
          }
          if (user.deleted) {
            throw new Error("User is deleted in Job Manager");
          }
          return user;
        }),
      },
      actions: {
        signOutFirebase: () => {
          //console.log("ACTION signOutFirebase");
          void auth.signOut();
        },
        showUserAuthenticityError: ({ context }) => {
          const isDeleted = context.jmUser?.currentUser?.deleted;
          const isDisabled = context.jmUser?.currentUser?.isActive === false;

          if (isDeleted) {
            Toaster.error(
              "Your account has been deleted. Please contact support if you believe this is an error"
            );
          } else if (isDisabled) {
            Toaster.error(
              "Your account has been disabled. Please contact support if you believe this is an error"
            );
          } else {
            Toaster.error(
              "Your account does not exist in Job Manager. Please contact support"
            );
          }
        },
        notifyTenantMismatch: () => {
          Toaster.error("You were logged out because tenant changed.");
        },
        assignFirebaseUser: assign({
          firebaseUserDetails: (_, params) => {
            return { uid: params.user.uid, tenantId: params.user.tenantId };
          },
        }),
        unassignFirebaseUser: assign({
          firebaseUserDetails: () => {
            return null;
          },
        }),
        setSentryUser: (_, params) => {
          if (params.user.email) {
            Sentry.setUser({
              email: params.user.email,
            });
          }
        },
        setFirebaseTenantId: (_, params) => {
          //console.log("ACTION setFirebaseTenantId");
          if (params.tenantId === "jmadmin") {
            return;
          }
          auth.tenantId = params.tenantId;
        },
        reloadPage: () => {
          window.location.reload();
        },
      },
      guards: {
        // Because if we only change auth.tenantId, the current user doesn't change
        // See: https://cloud.google.com/identity-platform/docs/multi-tenancy-authentication#sign_in_with_tenants
        checkTenantMatches: ({ context }) => {
          //console.log("Checking tenants match guard", auth, context);
          assert(context.firebaseUserDetails);
          return context.firebaseUserDetails.tenantId === auth.tenantId;
        },
        hasFirebaseUser: ({ context }) => {
          //console.log("Has firebase user guard", auth, context);
          return !!context.firebaseUserDetails;
        },
      },
    })
  );
  //console.log({ state: state.value, context: state.context });

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      //console.log("Firebase auth state changed", user);
      if (user) {
        send({
          type: "FIREBASE_SIGNED_IN",
          user: user,
        });
      } else {
        send({ type: "FIREBASE_SIGNED_OUT" });
      }
    });
  }, [send]);

  if (isCustomer) {
    return (
      <TenantBackground>
        <Suspense>
          <CustomerApp />
        </Suspense>
      </TenantBackground>
    );
  }

  if (!tenantId) {
    return (
      <div>
        <p>Job Manager: Please Specify a Tenant</p>
      </div>
    );
  }

  // Initialising tenant and firebase
  if (state.matches("loading")) {
    return (
      <TenantBackground>
        <Status />
      </TenantBackground>
    );
  }

  // Actually logging in
  if (state.matches("loggingIn")) {
    return (
      <TenantBackground>
        <Status text="Authenticating..." />
      </TenantBackground>
    );
  }

  if (state.matches("loggingOut")) {
    return (
      <TenantBackground>
        <Status text="Logging out..." />
      </TenantBackground>
    );
  }

  if (state.matches("loggedOut")) {
    return (
      <TenantBackground>
        <Login />
      </TenantBackground>
    );
  }

  if (state.matches("loggedIn")) {
    if (tenantId === "jmadmin") {
      return (
        <Suspense fallback={<Status text="Loading..." />}>
          <AdminApp />
        </Suspense>
      );
    }
    return (
      <TenantBackground>
        <Suspense fallback={<Status text="Loading..." />}>
          {isMobileOrTablet ? (
            <MobileApp />
          ) : (
            <DesktopApp key={`${tenantId}-${auth.currentUser?.uid}`} />
          )}
        </Suspense>
      </TenantBackground>
    );
  }
}

export const App = () => {
  const [devToolsConfig, setDevToolsConfig] = useAtom(devToolsConfigAtom);

  return (
    <QueryClientProvider client={queryClient}>
      {devToolsConfig.reactQueryDevTools.enabled && (
        <ReactQueryDevtoolsPanel
          style={{
            position: "fixed",
            bottom: 0,
            zIndex: 999999,
            height: 300,
            width: "100%",
          }}
          showCloseButton={true}
          setIsOpen={(isOpen) => {
            setDevToolsConfig((cfg) => ({
              ...cfg,
              reactQueryDevTools: {
                ...cfg.reactQueryDevTools,
                enabled: isOpen,
              },
            }));
          }}
          onDragStart={() => {}}
          // closeButtonProps={{
          //   onClick: () => {
          //     console.log("CLOSING");
          //     setReactQueryDevToolsConfig({
          //       ...reactQueryDevToolsConfig,
          //       enabled: false,
          //     });
          //   },
          // }}
        />
      )}
      <AuthContext />
      <NetworkStatus />
    </QueryClientProvider>
  );
};
