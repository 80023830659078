/**
 * THIS FILE IS AUTO-GENERATED. DO NOT MODIFY! */
export enum Permission {
  Disable_User = "Disable_User",
  Enable_User = "Enable_User",
  Create_User = "Create_User",
  Assign_Lead_To_Salesperson = "Assign_Lead_To_Salesperson",
  Assign_Role_Users = "Assign_Role_Users",
  Assign_User_Permissions = "Assign_User_Permissions",
  Bulk_Product_Import = "Bulk_Product_Import",
  Create_Branch = "Create_Branch",
  Create_Housing_Company_Image = "Create_Housing_Company_Image",
  Create_Job = "Create_Job",
  Create_Lead = "Create_Lead",
  Create_Product = "Create_Product",
  Create_Product_Tag = "Create_Product_Tag",
  Create_Role = "Create_Role",
  Create_Subscription = "Create_Subscription",
  Create_Supplier_Image = "Create_Supplier_Image",
  Create_Supplier = "Create_Supplier",
  Create_User_Identity = "Create_User_Identity",
  Delete_Housing_Companies = "Delete_Housing_Companies",
  Delete_Housing_Company_Image = "Delete_Housing_Company_Image",
  Delete_Product_Tag = "Delete_Product_Tag",
  Delete_Role = "Delete_Role",
  Delete_Supplier_Image = "Delete_Supplier_Image",
  Delete_Suppliers = "Delete_Suppliers",
  Delete_User = "Delete_User",
  Delete_User_Identity = "Delete_User_Identity",
  Override_Purchase_Order_Status = "Override_Purchase_Order_Status",
  Update_Job_Builder = "Update_Job_Builder",
  Update_Job_Client = "Update_Job_Client",
  Update_Job_Details = "Update_Job_Details",
  Update_Job_Site = "Update_Job_Site",
  Update_Lead = "Update_Lead",
  Update_Role = "Update_Role",
  Update_Supplier_Reviewers = "Update_Supplier_Reviewers",
  Update_User = "Update_User",
  Update_Product_Tag = "Update_Product_Tag",
  Query_Purchase_Orders = "Query_Purchase_Orders",
  Query_Lead = "Query_Lead",
  Query_Roles = "Query_Roles",
  Query_Upload_Request_Url = "Query_Upload_Request_Url",
  Query_User_Identities = "Query_User_Identities",
  Query_Users = "Query_Users",
  Query_Housing_Companies = "Query_Housing_Companies",
  Query_Jobs = "Query_Jobs",
  Query_Leads = "Query_Leads",
  Query_Subscriptions = "Query_Subscriptions",
  Query_Branches = "Query_Branches",
  Read_Branch_Name = "Read_Branch_Name",
}
export const PermissionConditionalPolicyInputTypes: Partial<
  Record<Permission, string>
> = {
  [Permission.Query_Purchase_Orders]: "PurchaseOrderFilterInput",
  [Permission.Query_Jobs]: "JobFilterInput",
  [Permission.Query_Leads]: "LeadFilterInput",
};
export const PermissionDescriptions: Partial<Record<Permission, string>> = {
  [Permission.Disable_User]: "",
  [Permission.Enable_User]: "",
  [Permission.Create_User]: "",
  [Permission.Assign_Lead_To_Salesperson]: "",
  [Permission.Assign_Role_Users]: "",
  [Permission.Assign_User_Permissions]: "",
  [Permission.Bulk_Product_Import]: "",
  [Permission.Create_Branch]: "",
  [Permission.Create_Housing_Company_Image]: "",
  [Permission.Create_Job]: "",
  [Permission.Create_Lead]: "",
  [Permission.Create_Product]: "",
  [Permission.Create_Product_Tag]: "",
  [Permission.Create_Role]: "",
  [Permission.Create_Subscription]: "",
  [Permission.Create_Supplier_Image]: "",
  [Permission.Create_Supplier]: "",
  [Permission.Create_User_Identity]: "",
  [Permission.Delete_Housing_Companies]: "",
  [Permission.Delete_Housing_Company_Image]: "",
  [Permission.Delete_Product_Tag]: "",
  [Permission.Delete_Role]: "",
  [Permission.Delete_Supplier_Image]: "",
  [Permission.Delete_Suppliers]: "",
  [Permission.Delete_User]: "",
  [Permission.Delete_User_Identity]: "",
  [Permission.Override_Purchase_Order_Status]: "",
  [Permission.Update_Job_Builder]: "",
  [Permission.Update_Job_Client]: "",
  [Permission.Update_Job_Details]: "",
  [Permission.Update_Job_Site]: "",
  [Permission.Update_Lead]: "",
  [Permission.Update_Role]: "",
  [Permission.Update_Supplier_Reviewers]: "",
  [Permission.Update_User]: "",
  [Permission.Update_Product_Tag]: "",
  [Permission.Query_Purchase_Orders]: "",
  [Permission.Query_Lead]: "",
  [Permission.Query_Roles]: "",
  [Permission.Query_Upload_Request_Url]: "",
  [Permission.Query_User_Identities]: "",
  [Permission.Query_Users]: "",
  [Permission.Query_Housing_Companies]: "",
  [Permission.Query_Jobs]: "",
  [Permission.Query_Leads]: "",
  [Permission.Query_Subscriptions]: "",
  [Permission.Query_Branches]: "",
  [Permission.Read_Branch_Name]: "",
};
